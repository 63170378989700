@import 'variables';
body{
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding: 0 !important;
  }
  .MuiDialog-root{
    .MuiDialogTitle-root, .MuiDialogActions-root{
      padding: 10px 20px !important;
      background: #F1F3FF;
      h2{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexMedium);
      }
    }
    .MuiDialogContent-root{
      padding: 10px 20px !important;
    }
  }
  .MuiTabs-scroller{
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 20px;
  }
  .MuiTabs-scrollable{
    margin-bottom: 20px !important;
  }
  .MuiTextField-root{
    label{
      @include elementStyles($size: 16px, $color:#000, $fontFamily: $ibmplexMedium);
    }
    input{
      @include elementStyles($size: 15px, $color:#000, $fontFamily: $ibmplexRegular);
    }
    .MuiOutlinedInput-input{
      padding: 14px !important;
    }
  }
  .MuiFormControl-root{
    legend{
      @include elementStyles($size: 13px, $color:#000, $fontFamily: $ibmplexMedium);
    }
    .MuiOutlinedInput-multiline{
      padding: 0;
    }
    .MuiFormControlLabel-root{
      .MuiTypography-root{
        @include elementStyles($size: 15px, $color:#000, $fontFamily: $ibmplexRegular);
      }
    }
  }
  .MuiTooltip-tooltip{
    font-size: 12px;
  }
  .MuiButton-containedPrimary{
    font-size: 13px;
  }
  .mui-styled-table{
    &.hovered-table{
      tr{
        background: inherit;
        cursor: pointer;
      }
    }
    .actions-cell{
      text-align: right;
      svg{
        cursor: pointer;
      }
    }
    thead{
      background: #B5BEDB !important;
      th{
        padding: 6px 10px;
        background: #b5bedb;
        &.MuiTableCell-stickyHeader{
          background: #B5BEDB !important;
        }
        span{
          @include elementStyles($size: 13px, $color: rgba(0,0,0,.6), $fontFamily: $ibmplexSemiBold);
          &.MuiTableSortLabel-root{
            &:hover{
              color: #000000;
              .MuiTableSortLabel-icon{
                color: #000000 !important;
              }
            }
            .MuiTableSortLabel-icon{
              // opacity: 0.5 !important;
            }
          }
          &.MuiTableSortLabel-active{
            color: #496ac5;
            .MuiTableSortLabel-icon{
              color: #496ac5 !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
    tbody{
      tr{
        &:nth-child(odd){
          // background: #ffffff;
        }
        &:nth-child(even){
          background: #F6F8FF;
        }
        &.Mui-selected, &:hover{
          background: #E6EBFB !important;
        }
        td{
          padding: 6px 10px;
          word-break: break-word;
        }
      }
    }
  }
  .filter-ui-drawer{
    input[type="text"], textarea, input[type="number"]{
      height: auto !important;
    }
    .filter-ui-drawer-header, .filter-ui-drawer-body, .filter-ui-drawer-footer{
        padding: 10px 15px;
    }
    .filter-ui-drawer-header{
        border-bottom: 1px solid #d7d7d7;
        h4{
            @include elementStyles($size: $font16, $color: #000000, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
    .filter-ui-drawer-body{
        overflow: auto;
    }
    .filter-ui-drawer-footer{
        border-top: 1px solid #d7d7d7;
    }
  }
  @media only screen and (max-width: 667px){
    .MuiDialog-paper{
      margin: 8px !important;
    }
    .MuiTabs-scrollButtons{
      width: 20px !important;
    }
    .MuiDialog-paperWidthSm{
      min-height: 300px !important;
    }
  }
  @media only screen and (orientation: landscape){
    .MuiDialog-paper{
      margin: 8px !important;
      // min-height: 320px !important;
    }
  }
  .MuiFormLabel-root.Mui-error {
    color:black;
  }

}
