@import "variables";
body {
  .serial-custom-dropdown{
    .serial-no-option{
        cursor:pointer;
         background-color: #fafafa;
        &:hover{
            background: #f2f2f2;
        }
    }
    .serial-no-load-more{
        text-align:center;
        color:#fff;
        background-color:#4050b5;
    }
  }
  .valuation-app-section {
    .note-message {
      @include elementStyles(
        $size: 11px,
        $fontFamily: $ibmplexRegular,
        $color: #984804
      );
    }
    .breadcrumbs-section {
      margin-bottom: 10px;
      nav {
        ol {
          li {
            font-size: 16px;
            a {
              color: #0000ee;
            }
            p{
              color: rgba(0, 0, 0, 0.54);
            }
          }
        }
      }
      .utils-cta {
        margin-left: auto;
        li {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    .add-bv-fbv-form {
      .edit-icon {
        position: absolute;
        top: 5px;
        right: 12px;
        cursor: pointer;
      }
      .form {
        .submit-bv-fbv {
          float: right;
          position: relative;
          right: 15px;
          top: 20px;
          li {
            margin-left: 10px;
          }
        }
      }
      .select-utils-type {
        label {
          @include elementStyles(
            $size: 13px,
            $fontFamily: $ibmplexMedium,
            $color: #000000
          );
        }
        .util-card {
          border-radius: 4px;
          padding: 0 10px;
          background: #f4f4f4;
        }
      }
      .engine-sv-table {
        margin-bottom: 10px;
        label {
          @include elementStyles(
            $size: 13px,
            $fontFamily: $ibmplexMedium,
            $color: #000000
          );
        }
        table,
        th,
        td {
          border: 1px solid #c4c4c4;
        }
        table {
          width: 100%;
          th,
          td {
            padding: 5px;
            @include elementStyles(
              $size: 13px,
              $fontFamily: $ibmplexMedium,
              $color: #000000
            );
          }
        }
      }

      .fixed-compute-cta {
        padding: 6px 6px 6px 12px;
        border-top: 1px solid #dbdada;
        &.MuiPaper-rounded{
          border-radius: 0px!important;
        }
        &.MuiPaper-elevation1{
          box-shadow: none!important;
        }
        .submit-bv-fbv{
          float: right;
          li{
            margin-left: 10px;
          }
        }
      }
    }
    .engine-mods{
      .title-blk{
        margin-bottom: 10px;
        h6{
          @include elementStyles($size: 13px,$fontFamily: $ibmplexMedium,$color: #000000);
        }
        button{
          margin-left: auto;
        }
      }
    }
    .bv-fbv-output {
      .output-widget-card {
        text-align: center;
        .widget-inner-wrapper {
          border-bottom: 1px solid #c4c4c4;
          padding-bottom: 20px;
          &:last-child {
            margin-bottom: 50px;
          }
        }
      }
      .output-fbv-card {
        margin-bottom: 15px;
        padding: 15px;
        h4 {
          @include elementStyles(
            $size: 15px,
            $fontFamily: $ibmplexMedium,
            $color: #000000,
            $margin: 0 0 4px 0
          );
        }
        p {
          @include elementStyles(
            $size: 13px,
            $fontFamily: $ibmplexRegular,
            $color: #000000
          );
          span {
            font-family: $ibmplexMedium;
            padding-left: 5px;
          }
        }
        button {
          margin-left: auto;
        }
      }
    }
    .lev-step-rental {
      background: #f1f1f1;
      padding: 10px;
      h4 {
        margin-bottom: 10px;
      }
      table,
      th,
      td {
        border: 1px solid #c4c4c4;
        border-collapse: collapse;
      }
      table {
        width: 100%;
        th {
          @include elementStyles(
            $size: 13px,
            $fontFamily: $ibmplexMedium,
            $color: #000000
          );
        }
        th,
        td {
          padding: 5px 10px;
        }
      }
    }
    .hla-section {
      .assembly-sections {
        padding: 30px 10px 10px 10px;
        position: relative;
        .edit-icon {
          position: absolute;
          top: 5px;
          right: 10px;
          cursor: pointer;
        }
        .event-wrapper {
          background: #f1f3ff;
          padding: 10px;
          h4 {
            @include elementStyles(
              $size: 15px,
              $fontFamily: $ibmplexMedium,
              $color: rgba(0, 0, 0, 0.6)
            );
          }
          span {
            margin-left: auto;
            cursor: pointer;
          }
        }
        .assembly-card {
          margin-bottom: 15px;
          .assembly-fields-blk {
            padding: 10px;
          }
        }
      }
      .hla-submit-cta {
        ul {
          float: right;
          li {
            margin-left: 15px;
          }
        }
      }
    }
  }

  .valuation-sections {
    .valuation-title {
      margin-bottom: 16px;
      h1 {
        @include elementStyles(
          $size: 16px,
          $fontFamily: $ibmplexRegular,
          $color: #0000ee
        );
      }
      p {
        @include elementStyles(
          $size: 14px,
          $fontFamily: $ibmplexRegular,
          $color: #838383
        );
      }
    }
    h1 {
      @include elementStyles(
        $size: 18px,
        $fontFamily: $ibmplexRegular,
        $color: #000000
      );
    }
    .warning-text {
      margin: 2px 0 0 5px;
      font-size: 10px;
      padding: 2px 4px;
      color: rgb(0, 0, 0);
      border-radius: 3px;
      background: rgb(255, 241, 182);
    }
    .valuation-log-info {
      padding: 10px;
      margin-bottom: 16px;
      ul {
        li {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
          p {
            @include elementStyles(
              $size: 13px,
              $fontFamily: $ibmplexRegular,
              $color: #000000
            );
          }
          h6 {
            @include elementStyles(
              $size: 14px,
              $fontFamily: $ibmplexSemiBold,
              $color: #000000
            );
          }
        }
        &.top-info {
          .list-inline-item {
            position: relative;

            &.forecast-li {
              .forecast-edit-icon {
                cursor: pointer;
                position: absolute;
                right: -16px;
                font-size: 16px;
              }
            }
            &.forcast-edit-mode {
              .MuiFormControl-marginNormal {
                margin-top: auto;
              }
            }
            svg {
              &.forcast-cancel-icon,
              &.forcast-done-icon {
                cursor: pointer;
                font-size: 18px;
                margin-top: 25px;
              }
            }
          }
        }
      }
      // button{
      //   float: right;
      // }
    }
    .maintanence-events {
      p {
        @include elementStyles(
          $size: 13px,
          $fontFamily: $ibmplexRegular,
          $color: #d7d7d7
        );
      }
      h6 {
        @include elementStyles(
          $size: 14px,
          $fontFamily: $ibmplexSemiBold,
          $color: #ffffff
        );
      }
      label {
        color: #d7d7d7;
      }
    }
    .event-info {
      .MuiTypography-colorTextSecondary,
      .MuiInputAdornment-positionEnd,
      .MuiInputBase-inputMultiline,
      .MuiInputAdornment-positionStart {
        color: #ffffff;
      }
      .MuiInput-underline:before {
        border-bottom: 1px solid rgba(255, 255, 255, 0.42);
      }
      .MuiInput-underline:after {
        border-bottom: 2px solid #ffffff;
      }
      .MuiIconButton-label {
        color: rgba(255, 255, 255, 0.82);
      }
      input {
        color: #ffffff;
      }
    }
    .valuation-logs-links {
      li {
        margin-left: 10px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .base-value-sec {
      .base-value-amount {
        margin: 30px 0;
        h6 {
          @include elementStyles(
            $size: 14px,
            $fontFamily: $ibmplexRegular,
            $color: #000000
          );
          margin-bottom: 6px;
        }
        .MuiPaper-root {
          text-align: center;
          padding: 24px 0;
        }
      }
    }
    .base-value-amount-future {
      margin: 30px 0;
      h6 {
        @include elementStyles(
          $size: 14px,
          $fontFamily: $ibmplexRegular,
          $color: #000000
        );
        margin-bottom: 6px;
      }
      .MuiPaper-root {
        text-align: center;
        padding: 24px 0;
      }
    }
    .base-value-sec-hla {
      .base-value-amount {
        margin: 10px 0;
        h6 {
          @include elementStyles(
            $size: 14px,
            $fontFamily: $ibmplexRegular,
            $color: #000000
          );
          margin-bottom: 6px;
        }
        .MuiPaper-root {
          text-align: center;
          padding: 24px 0;
        }
      }
      .component-breakdown {
        th {
          white-space: nowrap;
        }
      }
    }
    .base-value-sec-hla-intermediate {
      .base-value-amount {
        margin: -5px 0;
        h6 {
          @include elementStyles(
            $size: 14px,
            $fontFamily: $ibmplexRegular,
            $color: #000000
          );
          margin-bottom: 6px;
        }
        .MuiPaper-root {
          text-align: center;
          padding: 24px 0;
          color: #ffffff;
          background: linear-gradient(
            90deg,
            rgb(44, 69, 193),
            rgba(150, 95, 222, 0.93)
          );
        }
      }
    }
    .future-value-projection {
      padding-top: 40px;
      .future-value-graph {
        margin: 30px 0;
        h6 {
          @include elementStyles(
            $size: 14px,
            $fontFamily: $ibmplexRegular,
            $color: #000000
          );
          margin-bottom: 6px;
        }
      }
    }
    .other-links-options {
      .MuiPaper-root {
        margin-bottom: 15px;
        padding: 10px 10px;
        background: #3f51b5;
        @include elementStyles(
          $size: 14px,
          $fontFamily: $ibmplexRegular,
          $color: #fff
        );
      }
      p {
        @include elementStyles(
          $size: 12px,
          $fontFamily: $ibmplexRegular,
          $color: #856404
        );
        span {
          font-family: $ibmplexSemiBold;
        }
      }
    }
    .create-valuation-logs {
      .card {
        border-radius: 10px;

        &.active {
          cursor: pointer;
        }
        &.disabled {
          opacity: 0.5;
        }
        text-align: center;
        padding: 20px;
        width: 100%;
        h3 {
          @include elementStyles(
            $size: 16px,
            $fontFamily: $ibmplexRegular,
            $color: #000000
          );
        }
        p {
          font-size: 11px;
          margin-top: 3px;
        }
        img {
          width: 60px;
        }
        .card-usage-details {
          text-align: justify;
          margin-top: 20px;
          h6 {
            @include elementStyles(
              $size: 13px,
              $fontFamily: $ibmplexSemiBold,
              $color: #000000
            );
          }
          p {
          }
          button {
            margin-left: auto;
          }
        }
      }
      .value-card {
        background: #ffffff;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 25%);
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        .value-info {
          font-family: $ibmplexRegular;
          padding: 15px 10px;
          background-image: url("../../assets/images/card_bg.png");
          &.vl {
            &.color-blue {
              border-left: 10px solid #043bfc;
              color: #000;
            }
            h2 {
              font-size: 26px;
              font-family: $ibmplexSemiBold;
              text-align: left;
            }
            h4 {
              font-size: 14px;
              font-weight: 500;
              text-align: left;
              text-transform: uppercase;
            }
          }
        }
      }
      .llp-table {
        white-space: nowrap;
      }
    }
    .hla-initiate-card-title {
      color: #bd10e0;
      font-weight: 400;
      float: left;
      margin-bottom: 5px;
    }
    .hla-initiate-card {
      padding: 15px;
      margin-bottom: 30px;
      position: relative;
      background: whitesmoke;
      .MuiFormLabel-root {
        letter-spacing: 0.1px;
      }
    }
    .notice-message {
      z-index: 99;
      margin-left: 70px;
      margin-top: 4px;
    }
  }
  .llp-tabs {
    position: relative;
    top: 17px;
    .MuiTabs-scrollable {
      margin-bottom: auto !important;
    }
  }
  .checkbox-blk {
    position: relative;
    top: 15px;
  }
  .lease-encumbered-section {
    .create-log-breadcrumb {
      nav {
        ol {
          li {
            a {
              cursor: pointer;
            }
            h1 {
              font-size: 14px;
              color: #bd10e0;
              &.text-underline {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .create-log-cta {
      margin-bottom: 10px;
    }
    .create-log-basic-detail {
      background: #ffffff;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 15px;
      .title {
        font-size: 18px;
        color: #bd10e0;
      }
      .aircraft-basic-detail {
        margin-bottom: 30px;
        .operator-lessee-blk {
          background: #f6f6f7;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          padding: 20px;
          margin-top: 20px;
        }
      }
      .aircraft-lease-detail {
        .aircraft-lease-card {
          background: #fdfcf8;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          padding: 15px;
          .sub-card {
            background: #f6f6f7;
            border-radius: 4px;
            padding: 2px 10px 10px;
            min-height: 220px;
            .switcher-header {
              display: block;
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              font-weight: 400;
              line-height: 1;
              letter-spacing: 0.00938em;
              font-size: 14px;
              color: #0000008a;
              margin: 20px 0 10px;
            }
            .switcher {
              ul {
                text-align: center;
                li {
                  padding: 0px 10px 4px;
                  cursor: pointer;
                  min-width: 30px;
                  &:first-child {
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                  }
                  &:last-child {
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                  }
                  &.active {
                    background: #4844f9;
                    color: #ffffff;
                    span {
                      font-size: 12px;
                    }
                  }
                  &.inactive-bg {
                    background: #c4c4c4;
                    color: #000000;
                    span {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
            .annual-inflation-rate {
              .MuiFormLabel-root.Mui-error {
                color: #3f51b5 !important;
              }
              .MuiInput-underline.Mui-error {
                &:after {
                  border-bottom-color: #3f51b5 !important;
                }
              }
              .MuiFormHelperText-root.Mui-error {
                color: #000000 !important;
                background: rgb(255, 241, 182);
                border-radius: 4px;
                padding: 3px 5px;
              }
            }
          }
        }
        .stepped-rental-blk {
          border: 1px solid #d7d7d7;
          border-radius: 4px;
          padding: 10px 15px;
          .stepped-delete {
            position: relative;
            top: 40px;
            svg {
              cursor: pointer;
              &.delete-icon {
                color: #f50057;
              }
            }
          }
          p {
            svg {
              cursor: pointer;
              margin-right: 10px;
              color: #3f51b5;
            }
          }
          .stepped-rental-table {
            tbody {
              tr {
                td {
                  padding: 0px 35px 10px 15px;
                  &.txtfld {
                    position: relative;
                  }
                  svg {
                    cursor: pointer;
                  }
                  p {
                    svg {
                      vertical-align: text-top;
                      cursor: pointer;
                      margin-right: 10px;
                      color: #3f51b5;
                    }
                  }
                }
              }
            }
          }
        }
        .stepped-rental-heading {
          @include elementStyles(
            $size: 18px,
            $fontFamily: $ibmplexRegular,
            $color: #bd10e0
          );
          margin-bottom: 10px;
        }
        .disable-apu-label {
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }
  .lev-output-section {
    .lev-output-breadcrumb {
      margin-bottom: 15px;
      nav {
        ol {
          li {
            a {
              cursor: pointer;
            }
            h1 {
              font-size: 14px;
              color: #bd10e0;
              &.text-underline {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .output-view-blk {
      margin-bottom: 20px;
    }
    .lev-value-card-blk {
      margin-bottom: 20px;
      .value-card-header {
        font-size: 18px;
        margin-bottom: 10px;
      }
      .value-card {
        background: #ffffff;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 25%);
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        .value-info {
          padding: 15px 10px;
          background-image: url("../../assets/images/card_bg.png");
          min-height: 90px;
          &.vl {
            &.color-blue {
              border-left: 10px solid #043bfc;
              color: #000;
            }
            h4 {
              font-family: $ibmplexSemiBold;
              font-size: 20px;
            }
            span {
              font-size: 13px;
              display: inline-block;
            }
            .value {
              margin-top: 10px;
              p {
                span {
                  font-size: 16px;
                }
                &.right-align {
                  text-align: right;
                  margin-bottom: 3px;
                  font-size: 13px;
                }
              }
              span {
                font-size: 13px;
                &.right-align {
                  text-align: right;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .stepped-rental-data-blk {
      width: 76%;
      margin-bottom: 30px;
      .stepped-head {
        font-size: 14px;
        color: #a0a0a0;
        margin-bottom: 2px;
      }
      table {
        border-radius: 4px;
        padding: 10px 0px;
        th {
          font-size: 14px;
          color: #a0a0a0;
          margin-bottom: 15px;
        }
        td {
          font-size: 16px;
          padding: 5px 0px;
        }
      }
    }
  }
  .purchse-license-modal {
    table {
      width: 100%;
      thead {
        th {
          text-align: left;
          padding: 5px;
          @include elementStyles(
            $size: 13px,
            $fontFamily: $ibmplexSemiBold,
            $color: #000000
          );
        }
      }
      tbody {
        td {
          padding: 5px;
          @include elementStyles(
            $size: 16px,
            $fontFamily: $ibmplexRegular,
            $color: #000000
          );
          &:first-child {
            $fontFamily: $ibmplexSemiBold;
          }
          .inc-desc {
            background: #f1f1f1;
            border: 1px solid #c1c1c1;
            padding: 5px 10px;
            border-radius: 3px;
            cursor: pointer;
          }
          .qty-field {
            padding: 0 10px;
            input {
              width: 60px;
              text-align: center;
            }
          }
        }
      }
    }
    .purchase-address {
      background: #f1f1f1;
      padding: 10px;
      margin-top: 10px;
    }
    .strip-payment-blk {
      input,
      .StripeElement {
        display: block;
        margin: 10px 0 20px 0;
        max-width: 500px;
        padding: 10px 14px;
        font-size: 1em;
        font-family: "Source Code Pro", monospace;
        box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
          rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
        border: 0;
        outline: 0;
        border-radius: 4px;
        background: white;
      }

      input::placeholder {
        color: #aab7c4;
      }

      input:focus,
      .StripeElement--focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
          rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
      }

      .StripeElement.IdealBankElement,
      .StripeElement.FpxBankElement,
      .StripeElement.PaymentRequestButton {
        padding: 0;
      }

      .StripeElement.PaymentRequestButton {
        height: 40px;
      }
    }
  }
  .portfolio-section {
    .portfolio-status {
      display: inline-block;
      width: 100px;
      text-align: center;
      font-size: 13px;
      border-radius: 3px;
      padding: 5px;
      font-family: $ibmplexRegular;
      &.portfolio-status-0 {
        background: #bcaa05;
        color: #ffffff;
        border: 1px solid #8d7f04;
      }
      &.portfolio-status-1 {
        background: #069f3a;
        color: #ffffff;
        border: 1px solid #007b2a;
      }
      &.portfolio-status-2 {
        background: #d80909;
        color: #ffffff;
      }
    }
    .portfolio-breadcrumb {
      margin-bottom: 10px;
      .portfolio-report-cta {
        margin-left: auto;
        li {
          cursor: pointer;
          margin-left: 15px;
        }
        .list-graph-sw {
          border: 1px solid #d7d7d7;
          border-radius: 3px;
          display: inline-block;
          cursor: pointer;
          span {
            display: inline-block;
            padding: 7px 10px;
            &.active {
              background: #e0e0e0;
              color: #3f51b5;
            }
          }
        }
      }
    }
    .portfolio-basic-details {
      margin-bottom: 20px;
    }
    .portfolio-widget-card {
      position: relative;
      .analytic-sw {
        position: absolute;
        top: 5px;
        right: 5px;
        li {
          margin-left: 10px;
          &:first-child {
            span {
              font-family: $ibmplexMedium;
            }
            border-right: 1px solid #c4c4c4;
            padding-right: 10px;
          }
        }
      }
    }
    .portfolio-bv-cmv-card {
      margin-bottom: 20px;
      padding-bottom: 20px;
      padding-top: 10px;
      .widget-card {
        padding: 15px;
        background: #ffffff;
        border-radius: 3px;
        margin-right: 20px;
        h4 {
          @include elementStyles(
            $size: 16px,
            $fontFamily: $ibmplexMedium,
            $color: #000000,
            $margin: 0 0 4px 0
          );
        }
        button {
          margin-left: auto;
        }
      }
    }
    .portfolio-fbv-container {
      .portfolio-fbv-table {
        max-width: 100%;
        max-height: 36em;
        overflow: scroll;
        position: relative;
        table.outer-table {
          position: relative;
          border-collapse: collapse;
          td,
          th {
            padding: 0.25em;
          }
          thead.outer-head th {
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            top: 0;
            background: #f0f2f3;
            color: #6d9bb9;
            &.content-box {
              border-left: 1px solid #d7d7d7;
            }
            table {
              width: 100%;
            }
          }
          thead th:first-child {
            left: 0;
            z-index: 1;
          }
          tbody th {
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            left: 0;
            background: #f0f2f3;
            border-right: 1px solid #ccc;
            .asset-details {
              h4 {
                @include elementStyles(
                  $size: 16px,
                  $fontFamily: $ibmplexMedium,
                  $color: #000000,
                  $margin: 0 0 4px 0
                );
              }
              p,
              span {
                @include elementStyles(
                  $size: 13px,
                  $fontFamily: $ibmplexRegular,
                  $color: #000000
                );
                b {
                  font-family: $ibmplexMedium;
                }
              }
            }
          }
          tbody tr {
            border-bottom: 1px solid #d7d7d7;
            .content-box {
              border-left: 1px solid #d7d7d7;
            }
            &:first-child {
              border-top: 1px solid #d7d7d7;
            }
            td {
              @include elementStyles(
                $size: 15px,
                $fontFamily: $ibmplexRegular,
                $color: #000000
              );
              h4 {
                font-family: $ibmplexMedium;
              }
            }
          }
        }
      }
    }
    .profolio-analytic {
      .graph-desc {
        padding: 10px;
        h4 {
          @include elementStyles(
            $size: 16px,
            $fontFamily: $ibmplexMedium,
            $color: #000000,
            $margin: 0 0 4px 0
          );
        }
        p {
          @include elementStyles(
            $size: 13px,
            $fontFamily: $ibmplexRegular,
            $color: #000000,
            $margin: 0 0 4px 0
          );
        }
      }
    }
  }
  .portfolio-list-more {
    min-width: 200px;
    li {
      margin-bottom: 10px;
      h6 {
        @include elementStyles(
          $size: 16px,
          $fontFamily: $ibmplexRegular,
          $color: #000000,
          $margin: 0 0 4px 0
        );
      }
      .user-information {
        .profile-pic {
          float: left;
          margin-right: 10px;
          .user-details {
            p {
              @include elementStyles(
                $size: 16px,
                $fontFamily: $ibmplexRegular,
                $color: #000000,
                $margin: 0 0 4px 0
              );
            }
            span {
              @include elementStyles(
                $size: 11px,
                $fontFamily: $ibmplexRegular,
                $color: #000000
              );
            }
          }
        }
      }
    }
  }
  // Portfolio Projection Modal
  .portfolio-fbv-projection {
    .portfolio-fbv-cta {
      float: right;
      li {
        margin-left: 10px;
        cursor: pointer;
        border-right: 1px solid #c4c4c4;
        padding-right: 10px;
        &:last-child {
          border-right: none;
        }
      }
    }
    .portfolio-fbv-table {
      max-width: 100%;
      max-height: 36em;
      overflow: scroll;
      position: relative;
      table.outer-table {
        position: relative;
        border-collapse: collapse;
        td,
        th {
          padding: 0.25em;
        }
        thead.outer-head th {
          position: -webkit-sticky; /* for Safari */
          position: sticky;
          top: 0;
          background: #f0f2f3;
          color: #6d9bb9;
          &.content-box {
            border-left: 1px solid #d7d7d7;
          }
          table {
            width: 100%;
          }
        }
        thead th:first-child {
          left: 0;
          z-index: 1;
        }
        tbody th {
          position: -webkit-sticky; /* for Safari */
          position: sticky;
          left: 0;
          background: #f0f2f3;
          border-right: 1px solid #ccc;
          .asset-details {
            h4 {
              @include elementStyles(
                $size: 16px,
                $fontFamily: $ibmplexMedium,
                $color: #000000,
                $margin: 0 0 4px 0
              );
            }
            p,
            span {
              @include elementStyles(
                $size: 13px,
                $fontFamily: $ibmplexRegular,
                $color: #000000
              );
              b {
                font-family: $ibmplexMedium;
              }
            }
          }
        }
        tbody tr {
          border-bottom: 1px solid #d7d7d7;
          .content-box {
            border-left: 1px solid #d7d7d7;
          }
          &:first-child {
            border-top: 1px solid #d7d7d7;
          }
          td {
            @include elementStyles(
              $size: 15px,
              $fontFamily: $ibmplexRegular,
              $color: #000000
            );
            h4 {
              font-family: $ibmplexMedium;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .valuation-sections {
      .MuiGrid-spacing-xs-4 {
        .MuiGrid-item {
          padding: 10px 16px;
        }
      }
      .MuiFormControl-marginNormal {
        margin-top: auto;
      }
      .MuiFormControl-root {
        .MuiFormLabel-root {
          font-size: 14px;
        }
        .MuiInputBase-input {
          font-size: 14px;
          padding-top: 12px;
          .MuiInputBase-input.Mui-disabled {
            font-size: 14px;
          }
        }
      }
      .base-value-sec-hla {
        .base-value-amount {
          margin: 10px 0;
          h2 {
            font-size: 18px;
          }
          .MuiPaper-root {
            padding: 16px 0;
          }
        }
      }
      .label-value-card {
        h6 {
          font-size: 14px;
        }
      }
      .create-valuation-logs {
        padding-top: 0px;
        &.create-valuation-logs-mobile {
          margin-top: 45px;
          h3 {
            font-size: 14px;
          }
          .flex-not-centered {
            .card {
              padding: 15px;
              cursor: pointer;
              width: 100%;
            }
          }
          .MuiFormGroup-root {
            .MuiTypography-body1 {
              font-size: 14px;
            }
          }
        }
        &.create-valuation-logs-hthl-mobile {
          margin-top: auto;
          padding-top: 40px;
          .mobile-tablecell {
            white-space: nowrap;
          }
        }
        h1 {
          margin-bottom: auto;
          svg {
            margin-right: 5px;
          }
        }
        .base-value-amount {
          margin-top: 10px;
          h4 {
            margin-bottom: 8px;
          }
          .MuiPaper-root {
            padding: 10px 0;
            h2 {
              font-size: 18px;
            }
          }
        }
        .MuiGrid-spacing-xs-6 {
          .label-value-card {
            padding: 10px 24px;
          }
        }
        button {
          margin-bottom: 20px;
        }
        .lg-head-mobile {
          li {
            margin-top: auto !important;
            .MuiFormLabel-root {
              font-size: 13px;
              line-height: 15px;
            }
          }
        }
      }
      .notice-message {
        z-index: 99;
        margin-left: auto;
      }
      table {
        tbody {
          tr {
            td {
              padding: 8px;
            }
          }
        }
      }
      .hla-initiate-card {
        padding: 10px;
        &.lg-initiate-card {
          margin-bottom: 10px !important;
          ul {
            li {
              margin-top: 2px !important;
              margin-left: 8px !important;
            }
          }
        }
        .MuiFormLabel-root {
          font-size: 13px;
          line-height: 15px;
        }
      }
      .label-value-card {
        h6 {
          font-size: 14px;
        }
      }
      .MuiButton-containedPrimary,
      .MuiButton-outlinedPrimary {
        font-size: 11px !important;
      }
      .MuiBreadcrumbs-separator {
        position: relative;
        top: -1px;
      }
      .valuation-log-info {
        ul {
          li {
            button {
              h6 {
                font-size: 12px !important;
                position: relative;
                bottom: 5px;
                svg {
                  top: 5px;
                  font-size: 20px;
                }
              }
            }
          }
          &.top-info {
            .list-inline-item {
              padding-bottom: 5px;
              &.forcast-edit-mode {
                .MuiFormControl-marginNormal {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
      ul {
        li {
          button {
            h6 {
              font-size: 12px !important;
              position: relative;
              bottom: 4px;
              svg {
                top: 5px;
                font-size: 20px;
              }
            }
            &.MuiButton-root {
              padding: 4px 12px;
              svg {
                font-size: 20px;
              }
            }
          }
        }
      }
      .base-value-sec-hla-intermediate {
        .base-value-amount {
          h2 {
            font-size: 18px;
          }
          .value-card {
            padding: 12px;
          }
        }
      }
    }
    .llp-tabs {
      position: relative;
      top: 17px;
      .MuiTabs-scrollable {
        margin-bottom: auto !important;
      }
    }
    .import-export-mobile {
      top: -60px !important;
      .list-inline-item {
        .MuiButton-containedPrimary {
          font-size: 11px;
          svg {
            font-size: 16px;
          }
        }
      }
    }
    .MuiFormControl-marginNormal {
      margin-top: 6px;
      .MuiFormLabel-root {
        font-size: 14px;
      }
    }
    table {
      thead,
      tbody {
        tr {
          th {
            padding: 8px !important;
            white-space: nowrap !important;
          }
        }
        td {
          padding: 8px !important;
          white-space: nowrap !important;
          font-size: 13px !important;
        }
        .MuiInputBase-input {
          font-size: 13px !important;
        }
      }
    }
    .btn-save {
      margin-left: 5px;
    }
    .lev-output-section {
      .lev-output-breadcrumb {
        nav {
          ol {
            li {
              h1 {
                font-size: 12px;
              }
            }
          }
        }
        .MuiButton-containedPrimary {
          font-size: 12px;
        }
        .MuiButton-root {
          padding: 4px 12px;
          svg {
            font-size: 20px;
          }
        }
      }
      .label-value-card {
        p {
          font-size: 12px;
        }
      }
      .lev-value-card-blk {
        .value-card-header {
          font-size: 15px;
        }
        .value-card {
          .value-info {
            &.vl {
              h4 {
                font-size: 16px;
              }
              span {
                font-size: 12px;
              }
              .value {
                margin-top: 5px;
                p {
                  span {
                    font-size: 14px;
                  }
                }
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      table {
        thead,
        tbody {
          tr {
            th,
            td {
              white-space: nowrap;
            }
          }
        }
      }
      .stepped-rental-data-blk {
        .stepped-head {
          margin-bottom: auto;
        }
        table {
          th {
            font-size: 12px;
          }
          td {
            white-space: nowrap;
            font-size: 13px;
          }
        }
      }
    }
    .lease-encumbered-section {
      .create-log-breadcrumb {
        margin-bottom: 10px;
        nav {
          ol {
            li {
              h1 {
                font-size: 12px;
              }
            }
          }
        }
      }
      .create-log-cta {
        label,
        input {
          font-size: 14px;
        }
        label,
        input {
          font-size: 14px;
        }
        button {
          padding: 6px 18px;
        }
      }
      .create-log-basic-detail {
        .title {
          font-size: 16px;
        }
        .aircraft-basic-detail,
        .aircraft-lease-detail {
          margin-bottom: 20px;
          .MuiFormControl-root {
            margin-top: auto;
            margin-bottom: 5px;
            label,
            input {
              font-size: 14px;
            }
          }
          .MuiInputAdornment-root {
            .MuiTypography-root {
              font-size: 14px;
            }
          }
          .aircraft-lease-card {
            padding: 10px;
            .sub-card {
              padding-top: 10px;
              min-height: 150px;
              .switcher-header {
                margin-top: auto;
                font-size: 13px;
              }
              .switcher {
                ul {
                  li {
                    padding-bottom: 2px;
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }
          .stepped-rental-blk {
            svg {
              &.delete-icon {
                top: -10px;
              }
            }
          }
        }
        .aircraft-lease-detail {
          margin-bottom: auto;
          .MuiFormControl-root {
            margin-bottom: 10px;
          }
        }
      }
    }
    .rpt-an-issue {
      right: 0 !important;
    }
    .zsiq_theme1 {
      bottom: 10px !important;
      left: 0px !important;
      .zsiq_flt_rel {
        width: 40px !important;
        height: 40px !important;
      }
      .siqico-chat {
        &::before {
          font-size: 20px !important;
          line-height: 36px !important;
        }
      }
      .zsiq_float {
        .zsiq_cnt {
          left: 50px !important;
          display: none !important;
        }
      }
    }
    .edit-revision-history {
      width: auto !important;
      h4 {
        .mobile-margin {
          margin-left: 10px !important;
          button {
            .MuiButton-label {
              svg {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    .MuiDialogActions-root {
      display: block;
      p {
        margin-left: 8px;
        margin-bottom: 5px;
        font-size: 14px;
      }
      button {
        float: right;
      }
    }
    .MuiTabScrollButton-root.Mui-disabled {
      display: none;
    }
    .MuiDialogTitle-root,
    .MuiDialogContent-root {
      padding: 16px;
    }
    .user-management-module-section {
      .user-detail {
        .section-1 {
          .profile-pic {
            width: 75px !important;
            height: 75px !important;
            margin: 15px 0 0 15px !important;
          }
        }
      }
    }
  }
  @media (min-width: 601px) and (max-width: 767.98px) {
    .lev-output-section {
      .lev-output-breadcrumb {
        nav {
          ol {
            li {
              h1 {
                font-size: 12px;
              }
            }
          }
        }
      }
      .label-value-card {
        p {
          font-size: 12px;
        }
      }
      .lev-value-card-blk {
        .value-card-header {
          font-size: 15px;
        }
        .value-card {
          .value-info {
            &.vl {
              h4 {
                font-size: 16px;
              }
              span {
                font-size: 12px;
              }
              .value {
                margin-top: 5px;
                p {
                  span {
                    font-size: 14px;
                  }
                }
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      table {
        thead,
        tbody {
          tr {
            th,
            td {
              white-space: nowrap;
            }
          }
        }
      }
    }
    .lease-encumbered-section {
      .create-log-breadcrumb {
        margin-bottom: 10px;
        nav {
          ol {
            li {
              h1 {
                font-size: 12px;
              }
            }
          }
        }
      }
      .create-log-cta {
        label,
        input {
          font-size: 14px;
        }
        label,
        input {
          font-size: 14px;
        }
        button {
          padding: 6px 18px;
        }
      }
      .create-log-basic-detail {
        .title {
          font-size: 16px;
        }
        .aircraft-basic-detail,
        .aircraft-lease-detail {
          margin-bottom: 20px;
          .MuiFormControl-root {
            margin-top: auto;
            margin-bottom: 5px;
            label,
            input {
              font-size: 14px;
            }
          }
          .MuiInputAdornment-root {
            .MuiTypography-root {
              font-size: 14px;
            }
          }
          .aircraft-lease-card {
            padding: 10px;
            .sub-card {
              padding-top: 10px;
              min-height: 150px;
              .switcher-header {
                margin-top: auto;
                font-size: 13px;
              }
              .switcher {
                ul {
                  li {
                    padding-bottom: 2px;
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }
          .stepped-rental-blk {
            svg {
              &.delete-icon {
                top: 10px;
              }
            }
          }
        }
        .aircraft-lease-detail {
          margin-bottom: auto;
          .MuiFormControl-root {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .lev-output-section {
      .lev-output-breadcrumb {
        nav {
          ol {
            li {
              h1 {
                font-size: 12px;
              }
            }
          }
        }
      }
      .lev-value-card-blk {
        .value-card-header {
          font-size: 16px;
        }
        .value-card {
          .value-info {
            &.vl {
              h4 {
                font-size: 18px;
              }
              .value {
                p {
                  span {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
      table {
        thead,
        tbody {
          tr {
            th,
            td {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
